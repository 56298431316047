export const arEG = {
  components: {
    MuiBreadcrumbs: {
      defaultProps: {
        expandText: 'إظهار المسار'
      }
    },
    MuiTablePagination: {
      defaultProps: {
        getItemAriaLabel: (type) => {
          if (type === 'first') {
            return 'ذهاب للصفحة الأولى';
          }

          if (type === 'last') {
            return 'ذهاب للصفحة الأخيرة';
          }

          if (type === 'next') {
            return 'الصفحة التالية';
          } // if (type === 'previous') {

          return 'الصفحة السابقة';
        },
        labelRowsPerPage: 'عدد الصفوف في الصفحة:',
        labelDisplayedRows: ({ from, to, count }) => `${from}-${to} من ${count !== -1 ? count : ` أكثر من${to}`}`
      }
    },
    MuiRating: {
      defaultProps: {
        getLabelText: (value) => `${value} ${value !== 1 ? 'نجوم' : 'نجمة'}`,
        emptyLabelText: 'فارغ'
      }
    },
    MuiAutocomplete: {
      defaultProps: {
        clearText: 'مسح',
        closeText: 'إغلاق',
        loadingText: 'يتم التحميل…',
        noOptionsText: 'لا يوجد خيارات',
        openText: 'فتح'
      }
    },
    MuiAlert: {
      defaultProps: {
        closeText: 'إغلاق'
      }
    },
    MuiPagination: {
      defaultProps: {
        'aria-label': 'التنقل عبر الصفحات',
        getItemAriaLabel: (type, page, selected) => {
          if (type === 'page') {
            return `${selected ? '' : 'ذهاب إلى '} صفحة ${page}`;
          }

          if (type === 'first') {
            return 'ذهاب للصفحة الأولى';
          }

          if (type === 'last') {
            return 'ذهاب للصفحة الأخيرة';
          }

          if (type === 'next') {
            return 'الصفحة التالية';
          } // if (type === 'previous') {

          return 'الصفحة السابقة';
        }
      }
    }
  }
};
