import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
// material
import { Typography } from '@material-ui/core';
//
import { varFadeInUp } from './variants';
//
import useLocales from '../../hooks/useLocales';
// ----------------------------------------------------------------------

TextAnimate.propTypes = {
  text: PropTypes.string,
  variants: PropTypes.object,
  sx: PropTypes.object
};

export default function TextAnimate({ text, variants, sx, ...other }) {
  const { currentLang } = useLocales();

  const split = currentLang.value === 'ar' ? ' ' : '';

  return (
    <Typography
      component={motion.h1}
      sx={{
        typography: 'h1',
        overflow: 'hidden',
        display: 'inline-flex',
        ...sx,
        '& span': {
          [split.length ? 'ml' : 'mr']: split.length
        }
      }}
      {...other}
    >
      {text.split(split).map((letter, index) => (
        <motion.span
          key={index}
          variants={variants || varFadeInUp}
          style={{
            [!letter.trim().length ? 'marginLeft' : 'marginRight']: !letter.trim().length || split.length ? 4 : 0
          }}
        >
          {letter}
        </motion.span>
      ))}
    </Typography>
  );
}
