import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  posts: [],
  post: null,
  recentPosts: [],
  hasMore: true,
  index: 0,
  step: 11
};

const slice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET POST INFINITE
    getPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMorePosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    // GET TAG POSTS INFINITE
    getTagPostsInitial(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    getMoreTagPosts(state) {
      const setIndex = state.index + state.step;
      state.index = setIndex;
    },

    noHasMore(state) {
      state.hasMore = false;
    },

    // GET POST
    getPostSuccess(state, action) {
      state.isLoading = false;
      state.post = action.payload;
    },

    // POST COMMENT
    addCommentSuccess(state, action) {
      state.isLoading = false;
      state.post.comments.push(action.payload);
      state.post.comment += 1;
    },
    // POST REPLY
    addReplySuccess(state, action) {
      state.isLoading = false;
      const { __id, reply } = action.payload;
      const _id = state.post.comments.findIndex((comment) => comment._id === __id);
      state.post.comments[_id].replies.push(reply);
    },

    // POST FAVORITE
    setFavoriteSuccess(state, action) {
      state.isLoading = false;
      const isChecked = action.payload instanceof Object;
      if (!isChecked) {
        const favX = state.post.favorites.findIndex((fav) => fav._id === action.payload);
        state.post.favorites.splice(favX, 1);
      } else {
        state.post.favorites.push(action.payload);
      }
      state.post.favorite += isChecked ? 1 : -1;
    },

    // GET RECENT POST
    getRecentPostsSuccess(state, action) {
      state.isLoading = false;
      state.recentPosts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { getMorePosts } = slice.actions;

// ----------------------------------------------------------------------

export function getAllPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/portfolio/works/all');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/portfolio/works', {
        params: { index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getTagPostsInitial(title, index, step) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/portfolio/tag', {
        params: { title, index, step }
      });
      const results = response.data.results.length;
      const { maxLength } = response.data;

      dispatch(slice.actions.getTagPostsInitial(response.data.results));

      if (results >= maxLength) {
        dispatch(slice.actions.noHasMore());
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPost(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/portfolio/post', {
        params: { title }
      });
      dispatch(slice.actions.getPostSuccess(response.data.post));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addComment(comment, callback) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/portfolio/new-comment', comment);
      dispatch(slice.actions.addCommentSuccess(response.data.comment));
      if (callback instanceof Function) {
        callback(false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      if (callback instanceof Function) {
        callback(error);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function addReply(reply, callback) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/portfolio/new-reply', reply);
      dispatch(slice.actions.addReplySuccess(response.data));
      if (callback instanceof Function) {
        callback(false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      if (callback instanceof Function) {
        callback(error);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function setFavorite(favorite, callback) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/portfolio/set-favorite', favorite);
      dispatch(slice.actions.setFavoriteSuccess(response.data.favorite));
      if (callback instanceof Function) {
        callback(false);
      }
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
      if (callback instanceof Function) {
        callback(error);
      }
    }
  };
}

// ----------------------------------------------------------------------

export function getRecentPosts(title) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/portfolio/posts/recent', {
        params: { title }
      });

      dispatch(slice.actions.getRecentPostsSuccess(response.data.recentPosts));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
