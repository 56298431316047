import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, AppBar, Toolbar, Container, Fab } from '@material-ui/core';
// Icon
import { Icon } from '@iconify/react';
import mailSendFill from '@iconify/icons-ri/mail-send-fill';
import moonFill from '@iconify/icons-eva/moon-fill';
import sunFill from '@iconify/icons-eva/sun-fill';
import settingsFill from '@iconify/icons-eva/settings-outline';
import sharpVerifiedUser from '@iconify/icons-ic/sharp-verified-user';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useLocales from '../../hooks/useLocales';
import useSettings from '../../hooks/useSettings';
import useAuth from '../../hooks/useAuth';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
//
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export default function MainNavbar() {
  const { user } = useAuth();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const { onChangeDirection, themeMode, onChangeMode } = useSettings();
  const { translate, currentLang } = useLocales();
  const isHome = pathname === '/';

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: 'transparent' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink to="/">
            <MHidden width="mdDown">
              <Logo {...{ isHome, isOffset, themeMode, brand: 'full' }} />
            </MHidden>
            <MHidden width="mdUp">
              <Logo {...{ isHome, isOffset, themeMode, brand: 'single' }} />
            </MHidden>
          </RouterLink>
          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>

          <RouterLink to="/contact-me" style={{ textDecoration: 'none' }}>
            <MHidden width="mdDown">
              <Fab variant="extended" size="small">
                <Icon icon={mailSendFill} fontSize="large" />
                {translate('Contact me')}
              </Fab>
            </MHidden>
            <MHidden width="mdUp">
              <Fab size="small" sx={{ width: 36, height: 36 }}>
                <Icon icon={mailSendFill} fontSize="large" />
              </Fab>
            </MHidden>
          </RouterLink>

          <Fab
            color="default"
            size="small"
            sx={{
              width: 36,
              height: 36,
              ml: 1,
              ...(currentLang.value !== 'ar'
                ? {
                    fontSize: '1.15em',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    lineHeight: 1.6
                  }
                : {})
            }}
            value={currentLang.value === 'ar' ? 'ltr' : 'rtl'}
            onClick={onChangeDirection}
          >
            {currentLang.value === 'ar' ? 'EN' : 'ع'}
          </Fab>

          <Fab
            size="small"
            sx={{
              width: 36,
              height: 36,
              color: themeMode === 'dark' ? 'primary.main' : 'text.disabled',
              bgcolor: themeMode === 'dark' ? 'common.white' : 'grey.900',
              boxShadow: (theme) => theme.customShadows.z12,
              ml: 1,
              '&:hover': {
                color: themeMode === 'dark' ? 'grey.900' : 'text.disabled',
                bgcolor: themeMode === 'dark' ? 'common.white' : 'grey.900'
              }
            }}
            value={themeMode === 'dark' ? 'light' : 'dark'}
            onClick={(e) => {
              onChangeMode({
                ...e,
                target: {
                  ...e.target,
                  value: themeMode === 'dark' ? 'light' : 'dark'
                }
              });
            }}
          >
            <Icon icon={themeMode === 'dark' ? sunFill : moonFill} width={24} height={24} />
          </Fab>

          {(user &&
            ((user.role === 'admin' && (
              <RouterLink
                to="/dashboard"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Fab color="default" size="small" sx={{ width: 36, height: 36, ml: 1 }}>
                  <Icon icon={settingsFill} fontSize="large" />
                </Fab>
              </RouterLink>
            )) || (
              <RouterLink
                to="/account"
                style={{
                  textDecoration: 'none'
                }}
              >
                <Fab color="default" size="small" sx={{ width: 36, height: 36, ml: 1 }}>
                  <Icon icon={settingsFill} fontSize="large" />
                </Fab>
              </RouterLink>
            ))) || (
            <RouterLink
              to="/auth"
              style={{
                textDecoration: 'none'
              }}
            >
              <Fab color="default" size="small" sx={{ width: 36, height: 36, ml: 1 }}>
                <Icon icon={sharpVerifiedUser} fontSize="large" />
              </Fab>
            </RouterLink>
          )}

          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={navConfig} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
    </AppBar>
  );
}
