// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  dashboard: getIcon('ic_dashboard'),
  users: getIcon('ic_users'),
  portfolio: getIcon('ic_portfolio'),
  webrtc: getIcon('ic_webrtc'),
  blog: getIcon('ic_blog'),
  chats: getIcon('ic_chats'),
  mail: getIcon('ic_mail'),
  kanban: getIcon('ic_kanban')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'Dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'Management',
    items: [
      // MANAGEMENT : USERS
      {
        title: 'Users',
        path: PATH_DASHBOARD.users.root,
        icon: ICONS.users,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.users.profile },
          { title: 'cards', path: PATH_DASHBOARD.users.cards },
          { title: 'list', path: PATH_DASHBOARD.users.list },
          { title: 'create', path: PATH_DASHBOARD.users.newUser },
          { title: 'edit', path: PATH_DASHBOARD.users.editById },
          { title: 'account', path: PATH_DASHBOARD.users.account }
        ]
      },

      // MANAGEMENT : PORTFOLIO
      {
        title: 'My Portfolio',
        path: PATH_DASHBOARD.portfolio.root,
        icon: ICONS.portfolio,
        children: [
          { title: 'All Works', path: PATH_DASHBOARD.portfolio.works },
          { title: 'New Work', path: PATH_DASHBOARD.portfolio.newWork },
          { title: 'Categories', path: PATH_DASHBOARD.portfolio.categories }
        ]
      },

      // MANAGEMENT : WEBRTC
      {
        title: 'WebRTC',
        path: PATH_DASHBOARD.webrtc.root,
        icon: ICONS.webrtc,
        children: [
          { title: 'Devices', path: PATH_DASHBOARD.webrtc.devices },
          { title: 'Communications Options', path: PATH_DASHBOARD.webrtc.options },
          { title: 'Extra Components', path: PATH_DASHBOARD.webrtc.extra }
        ]
      },

      // MANAGEMENT : BLOG
      {
        title: 'Blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'All Posts', path: PATH_DASHBOARD.blog.posts },
          { title: 'New Post', path: PATH_DASHBOARD.blog.newPost },
          { title: 'Categories', path: PATH_DASHBOARD.blog.categories }
        ]
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'App',
    items: [
      { title: 'Chats', path: PATH_DASHBOARD.chats.root, icon: ICONS.chats },
      {
        title: 'Kanban',
        path: PATH_DASHBOARD.kanban,
        icon: ICONS.kanban
      }
    ]
  }
];

export default sidebarConfig;
