import { withStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------

const GlobalStyles = withStyles((theme) => ({
  '@global': {
    ':root[dir="rtl"] *': {
      fontFamily: 'Almarai',
      '& .MuiFormControlLabel-label': {
        fontFamily: 'Almarai'
      },
      '& .ql-editor.ql-blank': {
        textAlign: 'right'
      },
      '& .MuiTooltip-popper.MuiTooltip-popperInteractive.MuiTooltip-popperArrow .MuiTooltip-arrow': {
        right: 'unset'
      }
    },
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box'
    },
    '::-webkit-scrollbar': {
      width: '16px'
    },
    '::-webkit-scrollbar-thumb': {
      height: '56px',
      borderRadius: '8px',
      border: '4px solid transparent',
      backgroundClip: 'content-box',
      backgroundColor: 'transparent'
    },
    html: {
      scrollbarColor: '#aaa transparent',
      width: '100%',
      height: '100%',
      '-ms-text-size-adjust': '100%',
      '-webkit-overflow-scrolling': 'touch',
      '&:hover ::-webkit-scrollbar-thumb': {
        backgroundColor: '#aaa',
        transition: '1.8s'
      }
    },
    body: {
      width: '100%',
      height: '100%',
      overflow: 'overlay'
    },
    '#root': {
      width: '100%',
      height: '100%'
    },
    pre: {
      '&.ql-syntax, & code.hljs[class*="language-"]': {
        direction: 'ltr'
      }
    },
    input: {
      '&[type=number]': {
        MozAppearance: 'textfield',
        '&::-webkit-outer-spin-button': { margin: 0, WebkitAppearance: 'none' },
        '&::-webkit-inner-spin-button': { margin: 0, WebkitAppearance: 'none' }
      }
    },
    textarea: {
      '&::-webkit-input-placeholder': { color: theme.palette.text.disabled },
      '&::-moz-placeholder': { opacity: 1, color: theme.palette.text.disabled },
      '&:-ms-input-placeholder': { color: theme.palette.text.disabled },
      '&::placeholder': { color: theme.palette.text.disabled }
    },
    a: { color: theme.palette.primary.main },
    img: { display: 'block', maxWidth: '100%' },

    // Lazy Load Img
    '.blur-up': {
      WebkitFilter: 'blur(5px)',
      filter: 'blur(5px)',
      transition: 'filter 400ms, -webkit-filter 400ms'
    },
    '.blur-up.lazyloaded ': {
      WebkitFilter: 'blur(0)',
      filter: 'blur(0)'
    }
  }
}))(() => null);

export default GlobalStyles;
