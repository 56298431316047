import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import userReducer from './slices/user';
import portfolioReducer from './slices/portfolio';
import webrtcReducer from './slices/webrtc';
import blogReducer from './slices/blog';
import chatReducer from './slices/chat';
import kanbanReducer from './slices/kanban';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  portfolio: portfolioReducer,
  webrtc: webrtcReducer,
  blog: blogReducer,
  chat: chatReducer,
  kanban: kanbanReducer
});

export { rootPersistConfig, rootReducer };
