import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Box, /* Card, */ Stack, Link, /* Alert, */ Tooltip, Container, Typography, Button } from '@material-ui/core';
// hooks
import useAuth from '../../hooks/useAuth';
import useLocales from '../../hooks/useLocales';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { LoginForm } from '../../components/authentication/login';
import AuthFirebaseSocials from '../../components/authentication/AuthFirebaseSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();
  const { method, login } = useAuth();

  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <RootStyle title="Login | ManoCode">
      <AuthLayout>
        {translate('Go')} &nbsp;
        <Link underline="none" variant="subtitle2" component={RouterLink} to="/">
          {translate('Back')}
        </Link>
      </AuthLayout>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                {translate('Sign in to ManoCode')}
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{translate('Enter your details below.')}</Typography>
            </Box>

            <Tooltip title={translate('Login')}>
              <Box
                component="img"
                src="/static/icons/navbar/ic_users.svg"
                sx={{
                  width: 48,
                  height: 48,
                  backgroundColor: 'primary.main',
                  borderRadius: '2em'
                }}
              />
            </Tooltip>
          </Stack>

          {method === 'firebase' && <AuthFirebaseSocials />}

          {method !== 'auth0' ? (
            <LoginForm />
          ) : (
            <Button fullWidth size="large" type="submit" variant="contained" onClick={handleLoginAuth0}>
              {translate('Login')}
            </Button>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
