import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { forwardRef, useEffect, useCallback } from 'react';
// material
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
// utils
import track from '../utils/analytics';
// hooks
import useLocales from '../hooks/useLocales';
// ----------------------------------------------------------------------

const Page = forwardRef(({ children, title = '', ...other }, ref) => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const { translate } = useLocales();

  const TITLE = title
    .split(/-\s|\|/gi)
    .map((v) => translate(v.trim()))
    .join(' - ');

  const sendPageViewEvent = useCallback(() => {
    track.pageview({
      page_path: pathname
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    sendPageViewEvent();
  }, [sendPageViewEvent]);

  return (
    <Box
      ref={ref}
      {...other}
      id="move_top"
      sx={{ [theme.breakpoints.down('md')]: { maxWidth: '100vw', overflow: 'hidden' } }}
    >
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      {children}
    </Box>
  );
});

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string
};

export default Page;
