import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import infoFill from '@iconify/icons-eva/info-fill';
import emailFill from '@iconify/icons-eva/email-fill';

// routes
import { PATH_DOCS } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  } /* ,
  {
    title: 'WebRTC',
    icon: <Icon icon={webrtcIcon} {...ICON_SIZE} />,
    iconDesktop: <Icon icon={webrtcLogoIcon} {...ICON_SIZE_DESKTOP} />,
    path: PATH_PAGE.webrtc
  },
  {
    title: 'Blog',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_BLOG.root
  } */,
  {
    title: 'Who am i?',
    icon: <Icon icon={infoFill} {...ICON_SIZE} />,
    path: '/about-me'
  },
  /* {
    title: 'My Portfolio',
    path: '/portfolio',
    icon: <Icon icon={roundGrain} {...ICON_SIZE} />,
    children: [
      {
        subheader: 'Back-end',
        items: [
          { title: 'About us', path: PATH_PAGE.about },
          { title: 'Contact us', path: PATH_PAGE.contact },
          { title: 'FAQs', path: PATH_PAGE.faqs },
          { title: 'Pricing', path: PATH_PAGE.pricing },
          { title: 'Payment', path: PATH_PAGE.payment },
          { title: 'Maintenance', path: PATH_PAGE.maintenance },
          { title: 'Coming Soon', path: PATH_PAGE.comingSoon }
        ]
      },
      {
        subheader: 'Front-end',
        items: [
          { title: 'Login', path: PATH_AUTH.loginUnprotected },
          { title: 'Register', path: PATH_AUTH.registerUnprotected },
          { title: 'Reset password', path: PATH_AUTH.resetPassword },
          { title: 'Verify code', path: PATH_AUTH.verify }
        ]
      },
      {
        subheader: 'Servers Management',
        items: [
          { title: 'Page 404', path: PATH_PAGE.page404 },
          { title: 'Page 500', path: PATH_PAGE.page500 }
        ]
      },
      {
        subheader: 'Dashboard',
        items: [{ title: 'Dashboard', path: PATH_DASHBOARD.root }]
      }
    ]
  }, */
  {
    title: 'Contact me',
    icon: <Icon icon={emailFill} {...ICON_SIZE} />,
    path: PATH_DOCS
  }
];

export default menuConfig;
