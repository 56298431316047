import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet, Link as RouterLink } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// hook
import useLocales from '../../hooks/useLocales';
// components
import Logo from '../../components/Logo';
//
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';
//
// ----------------------------------------------------------------------

export default function MainLayout() {
  const {
    translate,
    currentLang: { value: lang }
  } = useLocales();
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  return (
    <>
      <MainNavbar />
      <Box sx={{ position: 'relative', zIndex: 999 }}>
        <Outlet />
      </Box>

      {!isHome ? (
        <MainFooter />
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            zIndex: 999,
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo brand="single" sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            <Typography variant="caption" component="p">
              © {new Date().toLocaleDateString(`${lang}-EG`, { year: 'numeric' })}. {translate('All rights reserved')}
              <br /> {translate('made by')} &nbsp;
              <Link component={RouterLink} to="/">
                manocode.me
              </Link>
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}
