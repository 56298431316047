// ----------------------------------------------------------------------
const { join: path } = require('path');

/* function path(root, sublink) {
  return arguments
    .map((p) => `'/'${p}`)
    .join('')
    .split('/')
    .filter(Boolean)
    .join(/\/+/gi, '/');
} */

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_WEBRTC = '/webrtc';
const ROOTS_BLOG = '/blog';
const ROOTS_PORTFOLIO = '/portfolio';
// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  webrtc: '/webrtc'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app')
  },
  chats: {
    root: path(ROOTS_DASHBOARD, '/chats'),
    new: path(ROOTS_DASHBOARD, '/chats/new'),
    conversation: path(ROOTS_DASHBOARD, '/chats/:conversationKey')
  },
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    profile: path(ROOTS_DASHBOARD, '/users/profile'),
    cards: path(ROOTS_DASHBOARD, '/users/cards'),
    list: path(ROOTS_DASHBOARD, '/users/list'),
    newUser: path(ROOTS_DASHBOARD, '/users/new'),
    editById: path(ROOTS_DASHBOARD, `/users/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/users/account')
  },
  portfolio: {
    root: path(ROOTS_DASHBOARD, ROOTS_PORTFOLIO),
    works: path(ROOTS_DASHBOARD, ROOTS_PORTFOLIO, '/works'),
    newWork: path(ROOTS_DASHBOARD, '/portfolio/new-work'),
    categories: path(ROOTS_DASHBOARD, '/portfolio/categories')
  },
  webrtc: {
    root: path(ROOTS_DASHBOARD, '/webrtc'),
    devices: path(ROOTS_DASHBOARD, '/webrtc/devices'),
    options: path(ROOTS_DASHBOARD, '/webrtc/options'),
    extra: path(ROOTS_DASHBOARD, '/webrtc/extra')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
    categories: path(ROOTS_DASHBOARD, '/blog/categories')
  }
};

export const PATH_DOCS = '/contact-me';

export const PATH_HOME = {
  root: '/',
  aboutme: '/about-me',
  contactme: '/contact-me'
};

export const PATH_PORTFOLIO = {
  root: ROOTS_PORTFOLIO
};

export const PATH_BLOG = {
  root: ROOTS_BLOG,
  tag: path(ROOTS_BLOG, '/tag/:title'),
  posts: path(ROOTS_BLOG, '/posts'),
  post: path(ROOTS_BLOG, '/post/:title'),
  edit: path(ROOTS_BLOG, '/edit/:title'),
  postById: path(ROOTS_BLOG, '/post/apply-these-7-secret-techniques-to-improve-event'),
  newPost: path(ROOTS_BLOG, '/new-post')
};

export const PATH_WEBRTC = {
  root: ROOTS_WEBRTC,
  devices: path(ROOTS_WEBRTC, '/devices'),
  options: path(ROOTS_WEBRTC, '/options'),
  extra: path(ROOTS_WEBRTC, '/extra')
};
