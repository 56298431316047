import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        { index: true, element: <Navigate to="/auth/login" replace /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { index: true, element: <Navigate to="/dashboard/app" replace /> },
        { path: 'app', element: <GeneralApp /> },
        {
          path: 'users',
          children: [
            { index: true, element: <Navigate to="/dashboard/users/profile" replace /> },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> }
          ]
        },
        {
          path: 'portfolio',
          children: [
            { index: true, element: <Navigate to="/dashboard/portfolio/works" replace /> },
            { path: 'works', element: <PortfolioWorks /> },
            { path: 'new-work', element: <PortfolioNewWork /> },
            { path: 'work/:title', element: <PortfolioWork /> },
            { path: 'edit/:title', element: <PortfolioEditWork /> }
          ]
        },
        {
          path: 'blog',
          children: [
            { index: true, element: <Navigate to="/dashboard/blog/posts" replace /> },
            { path: 'posts', element: <BlogPosts /> },
            { path: 'new-post', element: <BlogNewPost /> },
            { path: 'post/:title', element: <BlogPost /> },
            { path: 'edit/:title', element: <BlogEditPost /> }
          ]
        },
        {
          path: 'chats',
          children: [
            { index: true, element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        { path: 'kanban', element: <Kanban /> }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <LandingPage /> },
        { path: 'about-me', element: <AboutMe /> },
        { path: 'about-us', element: <About /> },
        {
          path: 'portfolio',
          children: [
            { index: true, element: <Portfolio /> },
            { path: 'work/:title', element: <PortfolioWork /> },
            { path: '*', element: <NotFoundBlog /> }
          ]
        },
        {
          path: 'contact-me',
          children: [
            { index: true, element: <ContactMe /> },
            { path: 'offer', element: <ContactMeOffer /> },
            { path: 'freelance', element: <ContactMeFreelance /> },
            { path: 'private-message', element: <ContactMePrivateMessage /> }
          ]
        },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
        {
          path: 'blog',
          children: [
            { index: true, element: <Blog /> },
            { path: 'post/:title', element: <Post /> },
            { path: 'tag/:title', element: <Tag /> },
            { path: '*', element: <NotFoundBlog /> }
          ]
        },
        {
          path: 'webrtc',
          children: [
            { index: true, element: <WebRtcOverview /> },
            { path: ':category', element: <WebRTCCategory /> },
            { path: ':category/:article', element: <WebRTCArticle /> },
            // DEVICES
            { path: 'microphone', element: <Microphone /> },
            { path: 'camera', element: <Camera /> },
            { path: 'screen', element: <Screen /> },
            { path: 'network', element: <Network /> },
            // OPTIONS
            { path: 'messaging', element: <Messaging /> },
            { path: 'video-chat', element: <VideoChat /> },
            { path: 'screen-sharing', element: <ScreenSharing /> },
            { path: 'transfer-data', element: <TransferData /> },
            // EXTRA COMPONENTS
            { path: 'recording', element: <Recording /> }
          ]
        }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogEditPost = Loadable(lazy(() => import('../pages/dashboard/BlogEditPost')));
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const PortfolioWorks = Loadable(lazy(() => import('../pages/dashboard/PortfolioWorks')));
const PortfolioNewWork = Loadable(lazy(() => import('../pages/dashboard/PortfolioNewWork')));
const PortfolioEditWork = Loadable(lazy(() => import('../pages/dashboard/PortfolioEditWork')));
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
// -> My Pages
const AboutMe = Loadable(lazy(() => import('../pages/AboutMe')));
const Portfolio = Loadable(lazy(() => import('../pages/Portfolio')));
const PortfolioWork = Loadable(lazy(() => import('../pages/PortfolioWork')));
const Blog = Loadable(lazy(() => import('../pages/Blog')));
const Post = Loadable(lazy(() => import('../pages/Post')));
const Tag = Loadable(lazy(() => import('../pages/Tag')));
const NotFoundBlog = Loadable(lazy(() => import('../pages/NotFoundBlog')));
// Contact Me
const ContactMe = Loadable(lazy(() => import('../pages/ContactMe')));
const ContactMeOffer = Loadable(lazy(() => import('../pages/contact-me/offer')));
const ContactMeFreelance = Loadable(lazy(() => import('../pages/contact-me/freelance')));
const ContactMePrivateMessage = Loadable(lazy(() => import('../pages/contact-me/private-message')));
// -> Minimal Pages
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
// WebRTC
const WebRtcOverview = Loadable(lazy(() => import('../pages/WebRtcOverview')));
// WebRTC -> :category
const WebRTCCategory = Loadable(lazy(() => import('../pages/WebRtcCategory')));
// WebRTC -> :category -> :article
const WebRTCArticle = Loadable(lazy(() => import('../pages/WebRtcArticle')));
// WebRTC -> Devices
const Microphone = Loadable(lazy(() => import('../pages/webrtc-overview/devices/DeviceMicrophone')));
const Camera = Loadable(lazy(() => import('../pages/webrtc-overview/devices/DeviceCamera')));
const Screen = Loadable(lazy(() => import('../pages/webrtc-overview/devices/DeviceScreen')));
const Network = Loadable(lazy(() => import('../pages/webrtc-overview/devices/DeviceNetwork')));
// WebRTC -> Options
const Messaging = Loadable(lazy(() => import('../pages/webrtc-overview/options/Messaging')));
const VideoChat = Loadable(lazy(() => import('../pages/webrtc-overview/options/VideoChat')));
const ScreenSharing = Loadable(lazy(() => import('../pages/webrtc-overview/options/ScreenSharing')));
const TransferData = Loadable(lazy(() => import('../pages/webrtc-overview/options/TransferData')));
// WebRTC -> Extra
const Recording = Loadable(lazy(() => import('../pages/webrtc-overview/extra/Recording')));
