import { Icon } from '@iconify/react';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import github from '@iconify/icons-eva/github-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Box } from '@material-ui/core';
// routes
import { PATH_HOME } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import useLocales from '../../hooks/useLocales';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'Mano',
    children: [
      { name: 'Who am i?', href: PATH_HOME.aboutme } /* ,
      { name: 'Blog', href: PATH_BLOG.root } */,
      { name: 'Contact me', href: PATH_HOME.contactme } /* ,
      { name: 'manocode@Gmail', href: 'mailto:manocode88@gmail.com' } */
    ]
  },
  /* {
    headline: 'My Portfolio',
    children: [
      { name: 'Back-end', href: '#' },
      { name: 'Servers Management', href: '#' }
    ]
  }, */
  {
    headline: 'Follow Me',
    children: [
      { name: 'Linkedin', icon: linkedinFill, href: 'https://linkedin.com/in/xalcrazy' },
      { name: 'GitHub', icon: github, href: 'https://github.com/abdalrahman-ahmed' }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const {
    translate,
    currentLang: { value: lang }
  } = useLocales();

  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={8} md={3} sx={{ pb: 5 }}>
            <Typography variant="h6" paragraph sx={{ pr: { md: 5 } }}>
              {`${translate('Abdelrahman')} ${translate('Abdrabo')}`}
            </Typography>
            <Typography variant="body1" sx={{ pr: { md: 5 } }}>
              {translate('Back-end Web Developer')}.
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              {translate(
                'More than 10 years of experience with a multitude of Linux-operating systems and MySQL/noSQL Databases, Javascript/NodeJS Libraries.'
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography variant="h6" paragraph>
                      {translate(headline)}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={/^mailto:|^https?:/.test(link.href) ? 'a' : RouterLink}
                        href={
                          /^mailto:|^https?:/.test(link.href)
                            ? [
                                link.href,
                                /^mailto:/.test(link.href) &&
                                  `?subject=${translate('Subject')}&body=${translate('Enter your message here.')}`
                              ]
                                .filter(Boolean)
                                .join('')
                            : link.href
                        }
                        sx={{ display: 'block' }}
                      >
                        {link.icon && [<Icon key="ic_social" icon={link.icon} inline />, ' ']}
                        {translate(link.name)}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink to="move_top" spy smooth>
              <Logo brand="single" sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>

            <Typography variant="caption" component="p">
              © {new Date().toLocaleDateString(`${lang}-EG`, { year: 'numeric' })}. {translate('All rights reserved')}
              <br /> {translate('made by')} &nbsp;
              <Link component={RouterLink} to="/">
                manocode.me
              </Link>
            </Typography>
          </Container>
        </Box>
      </Container>
    </RootStyle>
  );
}
